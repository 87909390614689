const toggle_list = {
    init() {
        $(".toggle_list_container .toggler:not('.activated')").on("click", (e) => {
            $(e.currentTarget).toggleClass("active")
            $(e.currentTarget).closest(".toggle_list_container").find(".toggle_sub").toggleClass("active")
        })

        // Put this class on toggler to say that it has been activated and do not need to rebind event if toggle_list
        // is call
        $(".toggle_list_container .toggler:not('.activated')").each((i, e) => {
            $(e).addClass('activated')
        })
    }
}

export default toggle_list