
const search = {
    fields: [],
    init(box, field, hide) {
        this.fields = $(field)
        $(box).on("input", function(e) {
            var value = $(this).val();
            if ($(this).data("lastval") != value) {
                $(this).data("lastval", value);
            
                search.fields.each(function(i) {
                    if (~$(this).text().indexOf(value)) {
                        $(this).parent(hide).removeClass("hide");
                    } else {
                        $(this).parent(hide).addClass("hide");
                    }
                });
            };
        })
    },
}

export default search;
